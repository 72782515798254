<template>
    <button
        :class="buttonClass"
        :disabled="disabled || loading"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        v-bind="$attrs"
    >
        <span v-if="loading" class="loader mr-2"></span>
        <slot name="icon-left" />
        <slot />
        <slot name="icon-right" />
    </button>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
    type: {
        type: String,
        default: 'success',
    },
    outline: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'md',
        validator(value) {
            return ['sm', 'md', 'lg', 'xl'].includes(value);
        },
    },
    fullWidth: {
        type: Boolean,
        default: false,
    },
});

const buttonClass = computed(() => {
    const baseClass =
        'rounded-full focus:outline-none transition flex items-center justify-center';
    const sizeClasses = {
        sm: 'px-3 py-1.5 text-sm',
        md: 'px-5 py-2 text-base',
        lg: 'px-7 py-3 text-lg',
        xl: 'px-9 py-4 text-xl',
    };
    const typeClasses = {
        primary: 'bg-blue-500 text-white hover:bg-blue-700',
        secondary: 'bg-gray-500 text-white hover:bg-gray-700',
        success: 'bg-[#14A085] text-white hover:bg-[#12876E]',
        danger: 'bg-red-500 text-white hover:bg-red-700',
        info: 'bg-teal-500 text-white hover:bg-teal-700',
    };
    const outlineClasses = {
        primary:
        'border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white',
        secondary:
        'border border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white',
        success:
        'border border-[#14A085] text-[#14A085] hover:bg-[#14A085] hover:text-white',
        danger:
        'border border-red-500 text-red-500 hover:bg-red-500 hover:text-white',
        info: 'border border-teal-500 text-teal-500 hover:bg-teal-500 hover:text-white',
    };
    const disabledClass = 'cursor-not-allowed opacity-50';
    const fullWidthClass = props.fullWidth ? 'w-full' : '';

    let classes = props.outline
        ? outlineClasses[props.type]
        : typeClasses[props.type];

    if (props.disabled || props.loading) {
        classes += ` ${disabledClass}`;
    }

    return `${baseClass} ${classes} ${sizeClasses[props.size]} ${fullWidthClass}`;
});
</script>


<style>
.loader {
    border: 2px solid transparent;
    border-top: 2px solid currentColor;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>