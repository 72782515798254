<template>
    <div v-if="isLoading" class="loading-spinner">
        <div class="spinner"></div>
        <span>Se încarcă..</span>
    </div>
    <div v-else>
        <AuthLayout v-if="!isAuthenticated">
            <router-view></router-view>
        </AuthLayout>
        <MainLayout v-else>
            <router-view></router-view>
        </MainLayout>
    </div>

    <!-- Error Popup -->
    <div v-if="showPopup" class="error-popup">
        <div class="error-message">
            Acțiunea nu este permisă!
        </div>
    </div>

    <ui-popup-message
        v-for="notification in notifications"
        :key="notification.id"
        :type="notification.type"
        :code="notification.code"
    />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useUserStore } from '@/stores/userStore';
import useUser from '@/composables/useUser';
import AuthLayout from '@/layouts/Auth.vue';
import MainLayout from '@/layouts/Main.vue';
import { storeToRefs } from 'pinia';
import { useNotificationStore } from '@/stores/notificationStore';
import UiPopupMessage from '@/components/Ui/PopupMessage.vue';

const userStore = useUserStore();
const { fetchUser } = useUser();
const isLoading = ref(true);
const showPopup = ref(false);

const isAuthenticated = computed(() => !!userStore.user);

// Function to disable user interactions and trigger popup
const disableUserInteraction = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isDevMode = urlParams.get('dev') === 'true';

    if (!isDevMode) {
        // Disable right-click and show popup
        document.addEventListener('contextmenu', (event) => {
            event.preventDefault();
            triggerPopup();
        });

        // Disable print and show popup
        window.onbeforeprint = () => {
            triggerPopup();
            return false;
        };

        // Disable certain keyboard shortcuts and show popup
        document.addEventListener('keydown', (event) => {
            if (event.key === 'F12' || 
                (event.ctrlKey && event.shiftKey && event.key === 'I') || 
                (event.ctrlKey && event.key === 'U')) {
                event.preventDefault();
                triggerPopup();
            }
        });

        // Detecting MacOS screenshot shortcut Command + Shift + 3 or 4
        document.addEventListener('keydown', (event) => {
            if ((event.metaKey && event.shiftKey && event.key === '3') ||
                (event.metaKey && event.shiftKey && event.key === '4')) {
                event.preventDefault();
                triggerPopup();
            }
        });

        // Disable text selection and drag events
        document.body.style.userSelect = "none";
        document.body.style.webkitUserSelect = "none";
        document.body.style.mozUserSelect = "none";
        document.body.style.msUserSelect = "none";
        document.addEventListener('dragstart', (event) => {
            event.preventDefault();
        });
    }
};

// Function to show popup
const triggerPopup = () => {
    showPopup.value = true;
    setTimeout(() => {
        showPopup.value = false;
    }, 1000);  // Show the popup for 1 second
};

onMounted(async () => {
    const token = localStorage.getItem('token');
    if (token && !userStore.user) {
        await fetchUser();
    }
    isLoading.value = false;

    // Call the disableUserInteraction function after the component is mounted
    disableUserInteraction();
});


const notificationStore = useNotificationStore();
const { notifications } = storeToRefs(notificationStore);
</script>

<style>
#app {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.loading-spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.1em;
    color: #666;
}

.spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #14A085;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Error Popup Styles */
.error-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.error-message {
    color: red;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
}

/* Disable text selection globally */
* {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

</style>
