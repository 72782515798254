import { createPinia, defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null,
        universities: [
            {
                id: 1,
                title: 'Universitatea de Medicină și Farmacie „Carol Davila” din București',
            },
            {
                id: 2,
                title: 'Universitatea de Medicină și Farmacie „Iuliu Hațieganu” din Cluj-Napoca',
            },
            {
                id: 3,
                title: 'Universitatea de Medicină și Farmacie „Grigore T. Popa” din Iași',
            },
            {
                id: 4,
                title: 'Universitatea de Medicină, Farmacie, Științe și Tehnologie „George Emil Palade" din Târgu Mureș',
            },
            {
                id: 5,
                title: 'Universitatea de Medicină și Farmacie „Victor Babeș” din Timișoara',
            },
            {
                id: 6,
                title: 'Universitatea de Medicină și Farmacie din Craiova',
            },
            { id: 7, title: 'Universitatea „Lucian Blaga” din Sibiu' },
            { id: 8, title: 'Universitatea Transilvania din Brașov' },
        ],
        faculties: [
            { id: 1, title: 'Medicină' },
            { id: 2, title: 'Stomatologie' },
            { id: 3, title: 'Farmacie' },
        ],
        classes: [
            { id: 1, title: 'X' },
            { id: 2, title: 'XI' },
            { id: 3, title: 'XII' },
            { id: 4, title: 'Absolvent' },
        ],
    }),
    actions: {
        getUser() {
            return this.user;
        },
        setUser(userData) {
            this.user = userData;
        },
        clearUser() {
            this.user = null;
        },
    },
});

const pinia = createPinia();
export default pinia;
