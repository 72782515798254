import { ref } from 'vue';
import apiClient from '@/services/api';
import useField from '@/composables/useField';
import useValidation from '@/composables/useValidation';
import { useUserStore } from '@/stores/userStore';
import router from '@/router';
import { useNotificationStore } from '@/stores/notificationStore';

export default function useAuth() {
    const userStore = useUserStore();
    const error = ref(null);
    const loading = ref(false);

    const loginField = useField('');
    const password = useField('');

    const { validateLoginField, validatePassword } = useValidation();
    const notificationStore = useNotificationStore();

    const login = async () => {
        error.value = null;

        const isLoginValid = validateLoginField(loginField);
        const isPasswordValid = validatePassword(password);

        if (!isLoginValid || !isPasswordValid) {
            return;
        }

        try {
            loading.value = true;
            const response = await apiClient.post('/login', {
                login: loginField.value,
                password: password.value,
            });

            localStorage.setItem('token', response.data.token);
            userStore.setUser(response.data.user);

            notificationStore.addNotification({
                type: 'success',
                code: response.data.success,
            });

            return response.data;
        } catch (err) {
            const data = err.response?.data || {};

            loginField.error = data.errors?.login;
            password.error = data.errors?.password;
            error.value = data.errors?.form || 'AN_ERROR_OCCURED';
        } finally {
            loading.value = false;
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        userStore.clearUser();
        router.push('/login');

        userStore.clearUser();
        notificationStore.addNotification({
            type: 'success',
            code: 'LOGOUT_SUCCESS',
        });
    };

    return {
        error,
        loading,
        loginField,
        password,
        validateLoginField: () => validateLoginField(loginField),
        validatePassword: () => validatePassword(password),
        login,
        logout,
    };
}
