import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/userStore';
import useUser from '@/composables/useUser';

const routes = [
    // Redirect from / to /login
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/statistici',
        name: 'statistici',
        component: () => import('@/views/Dashboard.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/teste',
        name: 'tests',
        component: () => import('@/views/Tests.vue'),
        meta: {
            pageTitle: 'Teste disponibile',
            requiresAuth: true,
        },
    },
    {
        path: '/testele-mele',
        name: 'my-tests',
        component: () => import('@/views/MyTests.vue'),
        meta: {
            pageTitle: 'Testele mele',
            requiresAuth: true,
        },
    },
    {
        path: '/test/:id/rezolvare',
        name: 'test-solve',
        component: () => import('@/views/TestSolve.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/test/:id/rezultat',
        name: 'test-result',
        component: () => import('@/views/TestResult.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/profilul-meu',
        name: 'myProfile',
        component: () => import('@/views/Profile.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/profil/:username',
        name: 'userProfile',
        component: () => import('@/views/Profile.vue'),
        meta: { requiresAuth: true },
        props: true,
    },
    {
        path: '/setari',
        name: 'settings',
        component: () => import('@/views/Settings.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/notificari',
        name: 'notifications',
        component: () => import('@/views/Notifications.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/documente',
        name: 'documents',
        component: () => import('@/views/Documents.vue'),
        meta: { requiresAuth: true },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();
    const { fetchUser } = useUser();
    const token = localStorage.getItem('token');

    if (token && !userStore.user) {
        await fetchUser();
    }

    if (to.meta.requiresAuth && !userStore.user) {
        next('/login');
    } else if (!to.meta.requiresAuth && userStore.user) {
        next('/statistici');
    } else {
        next();
    }
});

export default router;
