import { reactive } from 'vue';

const errorMessages = reactive({
    AN_ERROR_OCCURRED: 'A apărut o eroare. Te rugăm să încerci mai târziu.',

    EMAIL_REQUIRED: 'Emailul este obligatoriu.',
    EMAIL_INVALID: 'Formatul emailului este nu este valid.',
    EMAIL_UNIQUE: 'Emailul este deja folosit.',
    EMAIL_MINIMUM: 'Emailul este prea scurt.',
    EMAIL_MAXIMUM: 'Emailul este prea lung.',

    USERNAME_REQUIRED: 'Numele de utilizator este obligatoriu.',
    USERNAME_UNIQUE: 'Numele de utilizator există deja.',
    USERNAME_MINIMUM: 'Numele de utilizator este prea scurt.',
    USERNAME_MAXIMUM: 'Numele de utilizator este prea lung.',

    PASSWORD_REQUIRED: 'Parola este obligatorie.',
    PASSWORD_CONFIRMATION_REQUIRED: 'Confirmarea parolei este obligatorie.',
    PASSWORD_MINIMUM: 'Parola este prea scurtă.',

    NAME_REQUIRED: 'Numele este obligatoriu.',
    NAME_MINIMUM: 'Numele este prea scurt.',
    NAME_MAXIMUM: 'Numele este prea lung.',

    FIRST_NAME_REQUIRED: 'Prenumele este obligatoriu.',
    FIRST_NAME_MINIMUM: 'Prenumele este prea scurt.',
    FIRST_NAME_MAXIMUM: 'Prenumele este prea lung.',
    FIRST_NAME_INVALID: 'Prenumele nu valid.',

    LAST_NAME_REQUIRED: 'Numele este obligatoriu.',
    LAST_NAME_MINIMUM: 'Numele este prea scurt.',
    LAST_NAME_MAXIMUM: 'Numele este prea lung.',
    LAST_NAME_INVALID: 'Numele nu este valid.',

    LOGIN_REQUIRED: 'Email/Numele de utilizator este obligatoriu.',
    LOGIN_DOES_NOT_EXIST: 'Email/Numele de utilizator nu există.',
    LOGIN_INVALID: 'Email/Numele de utilizator nu este valid.',
    LOGIN_MINIMUM: 'Email/Numele de utilizator este prea scurt.',
    LOGIN_MAXIMUM: 'Email/Numele de utilizator este prea lung.',

    TEST_ID_REQUIRED: 'ID-ul testului este obligatoriu.',
    TEST_ID_DOES_NOT_EXIST: 'ID-ul testului nu există.',

    QUESTION_ID_REQUIRED: 'ID-ul întrebării este obligatoriu.',
    QUESTION_ID_DOES_NOT_EXIST: 'ID-ul întrebării nu există.',

    ANSWER_ID_REQUIRED: 'ID-ul răspunsului este obligatoriu.',
    ANSWER_ID_DOES_NOT_EXIST: 'ID-ul răspunsului nu există.',

    ANSWERS_IDS_REQUIRED: 'ID-urile răspunsurilor sunt obligatorii.',
    ANSWERS_IDS_INVALID: 'ID-urile răspunsurilor nu sunt valide.',
    ANSWERS_ID_REQUIRED: 'ID-ul răspunsului este obligatoriu.',
    ANSWERS_ID_DOES_NOT_EXIST: 'ID-ul răspunsului nu există.',

    TIME_REQUIRED: 'Timpul este obligatoriu.',
    TIME_INVALID: 'Timpul nu este valid.',
    TIME_MINIMUM: 'Timpul este prea scurt.',

    AVATAR_REQUIRED: 'Avatarul este obligatoriu.',
    AVATAR_INVALID: 'Avatarul nu este valid.',
    AVATAR_MAXIMUM: 'Avatarul este prea mare.',

    UNAUTHORIZED: 'Nu sunteți autorizat să efectuați această acțiune.',
    LOGIN_CREDENTIALS_DOES_NOT_MATCH:
        'Datele de autentificare nu se potrivesc.',
    NO_USER_ROLE_FOUND: 'Nu s-a găsit niciun rol de utilizator.',
    NO_ADMIN_ROLE_FOUND: 'Nu s-a găsit niciun rol de administrator.',

    TEST_NOT_FOUND: 'Testul nu a fost găsit.',
    TEST_HAS_NO_QUESTIONS: 'Testul nu are întrebări.',
    TEST_REQUEST_ALREADY_SENT:
        'Ai mai trimis deja o cerere pentru acest test. Te rugăm să aștepți confirmarea.',
    TEST_REQUEST_ALREADY_REJECTED:
        'Cererea ta de testare a fost deja respinsă în ultimele 24 de ore. Te rugăm să aștepți.',
    TEST_REQUEST_ALREADY_ACCEPTED:
        'Cererea ta de testare a fost deja acceptată în ultimele 24 de ore. Te rugăm să aștepți.',
    TEST_REQUEST_ALREADY_EXPIRED:
        'Cererea ta de testare a expirat în ultimele 24 de ore. Te rugăm să aștepți.',

    TEST_ATTEMPT_NOT_FOUND: 'Nu am identificat niciun test în desfășurare.',
    TEST_ATTEMPT_IN_PROGRESS: 'Ai deja acest test în desfășurare.',

    TEST_REQUEST_ID_REQUIRED: 'ID-ul cererii de testare este obligatoriu.',
    TEST_REQUEST_ID_DOES_NOT_EXIST: 'ID-ul cererii de testare nu există.',
    TEST_REQUEST_NOT_APPROVED: 'Cererea de testare nu a fost aprobată.',

    TEST_ATTEMPT_ID_REQUIRED: 'ID-ul testului este obligatoriu.',
    TEST_ATTEMPT_ID_DOES_NOT_EXIST: 'ID-ul testului nu există.',
    TEST_REQUEST_ALREADY_COMPLETED:
        'Testul a fost deja finalizat. Nu mai poți trimite răspunsuri.',

    ANSWER_NOT_FOUND: 'Răspunsul nu a fost găsit.',
    QUESTION_ALREADY_ANSWERED: 'Ai răspuns deja la această întrebare.',

    TEST_NOT_COMPLETED: 'Testul nu a fost finalizat.',

    NOTIFICATIONS_FETCH_FAILED: 'Notificările nu au putut fi încărcate.',
    NOTIFICATION_NOT_FOUND: 'Notificarea nu a fost găsită.',
    NOTIFICATION_UPDATE_FAILED: 'Notificarea nu a putut fi actualizată.',

    PASSWORD_CONFIRMATION_MISMATCH:
        'Parola și confirmarea parolei nu se potrivesc.',
    PASSWORD_MUST_BE_MIN_6: 'Parola trebuie să aibă cel puțin 6 caractere.',
    PASSWORD_MUST_BE_MAX_100: 'Parola poate avea maximum 100 de caractere.',
    PASSWORD_CONFIRMATION_MUST_BE_MIN_6:
        'Confirmarea parolei trebuie să aibă cel puțin 6 caractere.',
    PASSWORD_CONFIRMATION_MUST_BE_MAX_100:
        'Confirmarea parolei poate avea maximum 100 de caractere.',

    USER_NOT_FOUND: 'Utilizatorul nu a fost găsit.',
    USER_NOT_ASSOCIATED_WITH_CURRENT_SCHOOL_YEAR:
        'Contul nu este înrolat în anul de studiu curent.',
    USER_NOT_ASSOCIATED_WITH_ANY_SCHOOL_YEAR:
        'Contul nu este asociat cu niciun an de studiu.',

    SCHOOL_YEAR_REQUIRED: 'Anul de studiu este obligatoriu.',
    SCHOOL_YEAR_INVALID: 'Anul de studiu nu este valid.',

    UNIVERSITY_ID_REQUIRED: 'ID-ul universității este obligatoriu.',
    UNIVERSITY_ID_INVALID: 'ID-ul universității nu este valid.',
    UNIVERSITY_ID_DOES_NOT_EXIST: 'ID-ul universității nu există.',

    FACULTY_ID_REQUIRED: 'ID-ul facultății este obligatoriu.',
    FACULTY_ID_INVALID: 'ID-ul facultății nu este valid.',
    FACULTY_ID_DOES_NOT_EXIST: 'ID-ul facultății nu există.',

    CLASS_ID_REQUIRED: 'ID-ul clasei este obligatoriu.',
    CLASS_ID_INVALID: 'ID-ul clasei nu este valid.',
    CLASS_ID_DOES_NOT_EXIST: 'ID-ul clasei nu există.',

    CATEGORY_ID_REQUIRED: 'ID-ul categoriei este obligatoriu.',
    CATEGORY_ID_INVALID: 'ID-ul categoriei nu este valid.',
    CATEGORY_ID_DOES_NOT_EXIST: 'ID-ul categoriei nu există.',

    DOCUMENT_NOT_FOUND: 'Documentul nu a fost găsit.',
    FAILED_TO_FETCH_DOCUMENTS: 'Nu s-au putut încărca documentele.',

    DOWNLOAD_FAILED: 'A apărut o eroare la descărcare',
});

const successMessages = reactive({
    LOGIN_SUCCESS: 'Autentificare reușită.',
    LOGOUT_SUCCESS: 'Deconectare reușită.',
    OPERATION_SUCCESS: 'Operațiune reușită.',
    TESTS_FETCHED: 'Testele au fost încărcate cu succes.',
    TEST_FETCHED: 'Testul a fost încărcat cu succes.',
    TEST_REQUEST_SENT: 'Cererea de testare a fost trimisă.',
    NOTIFICATIONS_FETCHED: 'Notificările au fost încărcate cu succes.',
    TEST_ATTEMPTS_FETCHED: 'Testele au fost încărcate cu succes.',
    TEST_ATTEMPT_FETCHED: 'Testul a fost încărcat cu succes.',
    REMAINING_TIME_UPDATED: 'Timpul rămas a fost actualizat cu succes.',
    CATEGORIES_FETCHED: 'Categoriile au fost încărcate cu succes.',
    SETTINGS_UPDATED: 'Setările au fost actualizate cu succes.',
    AVATAR_UPDATED: 'Avatarul a fost actualizat cu succes.',
    TEST_ATTEMPT_CREATED: 'Testul a fost generat cu succes.',

    ANSWERS_SUBMITTED: 'Răspunsurile au fost trimise.',
    ANSWER_SUBMITTED: 'Răspunsul a fost salvat.',
    ANSWER_UPDATED: 'Răspunsul a fost actualizat.',

    NOTIFICATION_MARKED_AS_READ: 'Notificarea a fost marcată ca citită.',
});

export function useMessages() {
    const getErrorMessage = (code) => {
        if (!code || code.length === 0) {
            return null;
        }
        return errorMessages[code] || errorMessages.AN_ERROR_OCCURRED;
    };

    const getSuccessMessage = (code) => {
        if (!code || code.length === 0) {
            return null;
        }
        return successMessages[code] || successMessages.OPERATION_SUCCESS;
    };

    return {
        getErrorMessage,
        getSuccessMessage,
    };
}

export default { error: errorMessages, success: successMessages };
