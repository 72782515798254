import { useMessages } from './useMessages';
import { useRouter } from 'vue-router';

export default function useValidation() {
    const router = useRouter();

    const email = (field) => {
        if (!field.value) {
            field.error = 'EMAIL_REQUIRED';
            return false;
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(field.value)) {
            field.error = 'EMAIL_INVALID';
            return false;
        }
        field.validated = true;
        field.error = '';
        return true;
    };

    const validateLoginField = (field) => {
        if (!field.value) {
            field.error = 'EMAIL_REQUIRED';
            return false;
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const usernamePattern = /^[a-zA-Z0-9._-]{3,}$/;

        if (
            !emailPattern.test(field.value) &&
            !usernamePattern.test(field.value)
        ) {
            field.error = 'EMAIL_INVALID';
            return false;
        }

        field.validated = true;
        field.error = '';
        return true;
    };

    const validatePassword = (field, passwordConfirmation = field.value) => {
        if (!field.value && router.currentRoute.value.name !== 'settings') {
            field.error = 'PASSWORD_REQUIRED';
            return false;
        }

        if (
            field.value.length == 0 &&
            router.currentRoute.value.name == 'settings'
        ) {
            field.validated = true;
            field.error = '';
            return true;
        }

        if (field.value.length < 6) {
            field.error = 'PASSWORD_MUST_BE_MIN_6';
            return false;
        }

        if (field.value.length >= 100) {
            field.error = 'PASSWORD_MUST_BE_MAX_100';
            return false;
        }

        if (
            passwordConfirmation.value &&
            passwordConfirmation.value !== field.value
        ) {
            field.error = 'PASSWORD_CONFIRMATION_MISMATCH';
            return false;
        }

        field.validated = true;
        field.error = '';
        return true;
    };

    const validatePasswordConfirmation = (field, passwordField) => {
        if (!field.value) {
            field.error = 'PASSWORD_CONFIRMATION_REQUIRED';
            return false;
        }

        if (field.value !== passwordField.value) {
            field.error = 'PASSWORD_CONFIRMATION_MISMATCH';
            return false;
        }

        if (field.value.length < 6) {
            field.error = 'PASSWORD_CONFIRMATION_MUST_BE_MIN_6';
            return false;
        }

        if (field.value.length >= 100) {
            field.error = 'PASSWORD_CONFIRMATION_MUST_BE_MAX_100';
            return false;
        }

        field.validated = true;
        field.error = '';
        return true;
    };

    const validateFirstName = (field) => {
        if (!field.value) {
            field.error = 'FIRST_NAME_REQUIRED';
            return false;
        }
        if (field.value.length < 3) {
            field.error = 'FIRST_NAME_MINIMUM';
            return false;
        }
        if (field.value.length > 50) {
            field.error = 'FIRST_NAME_MAXIMUM';
            return false;
        }
        field.validated = true;
        field.error = '';
        return true;
    };

    const validateLastName = (field) => {
        if (!field.value) {
            field.error = 'LAST_NAME_REQUIRED';
            return false;
        }
        if (field.value.length < 3) {
            field.error = 'LAST_NAME_MINIMUM';
            return false;
        }
        if (field.value.length > 50) {
            field.error = 'LAST_NAME_MAXIMUM';
            return false;
        }
        field.validated = true;
        field.error = '';
        return true;
    };

    return {
        email,
        validateLoginField,
        validatePassword,
        validatePasswordConfirmation,
        validateFirstName,
        validateLastName,
    };
}
