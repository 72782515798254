<template>
    <Transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div v-if="isVisible" class="fixed bottom-4 right-4 z-50 cursor-pointer">
            <div :class="[bgColorClass, 'w-96 shadow-lg rounded-lg pointer-events-auto']">
                <div class="p-4">
                    <div class="flex items-start">
                        <div class="flex-shrink-0">
                            <component :is="icon" class="h-6 w-6 text-white" aria-hidden="true" />
                        </div>
                        <div class="ml-3 w-0 flex-1 pt-0.5">
                            <p class="text-sm font-medium text-white">
                                {{ message }}
                            </p>
                        </div>
                        <div class="ml-4 flex-shrink-0 flex">
                            <button
                                @click="close"
                                :class="[bgColorClass, 'rounded-md inline-flex text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-white']"
                            >
                                <span class="sr-only">Close</span>
                                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { computed, ref, onMounted, defineProps } from 'vue';
import { CheckCircleIcon, XCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import { useMessages } from '@/composables/useMessages';
import { useNotificationStore } from '@/stores/notificationStore';

const props = defineProps({
    type: {
        type: String,
        required: true,
        validator: (value) => ['success', 'error', 'warning', 'info'].includes(value)
    },
    code: {
        type: String,
        required: true
    }
});

const { getSuccessMessage, getErrorMessage } = useMessages();
const notificationStore = useNotificationStore();

const isVisible = ref(true);

const message = computed(() => {
    switch (props.type) {
        case 'success':
            return getSuccessMessage(props.code);
        case 'error':
            return getErrorMessage(props.code);
        case 'warning':
            // Add your warning message function here
            return '';
        case 'info':
            // Add your info message function here
            return '';
        default:
            return props.code;
    }
});

const icon = computed(() => {
    switch (props.type) {
        case 'success':
            return CheckCircleIcon;
        case 'error':
            return XCircleIcon;
        case 'warning':
            return ExclamationTriangleIcon;
        case 'info':
            return InformationCircleIcon;
        default:
            return null;
    }
});

const bgColorClass = computed(() => {
    switch (props.type) {
        case 'success':
            return 'bg-success-500';
        case 'error':
            return 'bg-danger-500';
        case 'warning':
            return 'bg-yellow-500';
        case 'info':
            return 'bg-blue-500';
        default:
            return 'bg-gray-500';
    }
});

const close = () => {
    isVisible.value = false;
    notificationStore.removeNotification({ type: props.type, code: props.code });
};

onMounted(() => {
    setTimeout(() => {
        close();
    }, 4000);
});
</script>