<template>
    <div class="bg-white shadow-md">
        <header class="h-16 flex items-center justify-between px-6">
            <!-- Mobile menu button -->
            <button class="text-gray-500 focus:outline-none lg:hidden" @click="$emit('toggle-sidebar')">
                <Bars3Icon class="h-6 w-6" />
            </button>
            
            <!-- Go to admin panel button -->
            <ui-button
                v-if="isAdmin"
                type="danger"
                size="sm"
                @click="goToAdmin"
                class="ml-2"
            >
                <template #icon-left>
                    <LinkIcon class="w-5 h-5 mr-2" />
                </template>
                Panou Admin
            </ui-button>

            <!-- Right-aligned container for the user menu -->
            <div class="flex items-center ml-auto relative">
                <button @click="toggleDropdown" class="flex items-center focus:outline-none">
                    <span class="text-gray-800 sm:inline text-md mr-2">{{ fullName }}</span>
                    <img :src="avatarUrl" alt="User avatar" class="w-8 h-8 rounded-full" style="object-fit: cover;">
                    <!-- <ChevronDownIcon class="h-5 w-5 text-gray-600 ml-2" /> -->
                </button>
                <!-- <div v-if="dropdownOpen" class="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg py-1">
                    <a href="#" class="block px-4 py-2 text-gray-800 hover:bg-gray-100 flex items-center">
                        <LinkIcon class="h-5 w-5 mr-2" /> Profilul meu
                    </a>
                    <a href="#" class="block px-4 py-2 text-gray-800 hover:bg-gray-100 flex items-center">
                        <Cog8ToothIcon class="h-5 w-5 mr-2" /> Setări
                    </a>
                    <a href="#" class="block px-4 py-2 text-gray-800 hover:bg-gray-100 flex items-center">
                        <ArrowLeftStartOnRectangleIcon class="h-5 w-5 mr-2" /> Deconectare
                    </a>
                </div> -->
            </div>
        </header>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { Bars3Icon } from '@heroicons/vue/24/outline';
import { useUserStore } from '@/stores/userStore';
import UiButton from '@/components/Ui/Button.vue';
import { LinkIcon } from '@heroicons/vue/24/outline';

const dropdownOpen = ref(false);

const toggleDropdown = () => {
    dropdownOpen.value = !dropdownOpen.value;
};

const handleClickOutside = (event) => {
    if (!event.target.closest('.relative')) {
        dropdownOpen.value = false;
    }
};

onMounted(() => {
    document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
});

const userStore = useUserStore();

const fullName = computed(() => userStore.user.full_name);

const avatarUrl = computed(() => {
    if (userStore.user?.avatar?.original_url) {
        return userStore.user.avatar.original_url;
    }
    return require('@/assets/exports/default-avatar.svg');
});

const isAdmin = computed(() => {
    if (userStore.user?.roles.length > 0) {
        return userStore.user.roles.some(role => role.title == 'Admin');
    }
    return false;
});

const goToAdmin = () => {
    const adminUrl = process.env.VUE_APP_ADMIN_URL;

    if (adminUrl) {
        window.open(adminUrl, '_self');
    } else {
        console.error('ADMIN_URL is not defined or is empty');
    }
};

</script>

<style scoped>
button:focus {
    outline: none;
}
</style>
