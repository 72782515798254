<template>
    <div class="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-teal-100">
        <div class="w-full max-w-md px-6 py-8">
            <div class="bg-white shadow-2xl rounded-3xl px-8 py-10">
                <div class="flex justify-center mb-8">
                    <img src="@/assets/exports/bacteria-transparent.png" alt="Logo" class="w-20 h-20 object-contain">
                </div>
                <h2 class="text-3xl font-bold text-center text-gray-800 mb-8">
                    grile-medicina.ro
                </h2>
                
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>
