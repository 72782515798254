import { defineStore } from 'pinia';

export const useNotificationStore = defineStore('notification', {
    state: () => ({
        notifications: [],
    }),
    actions: {
        addNotification(notification) {
            this.notifications.push(notification);
            setTimeout(() => {
                this.removeNotification(notification);
            }, 5000);
        },
        removeNotification(notification) {
            const index = this.notifications.indexOf(notification);
            if (index > -1) {
                this.notifications.splice(index, 1);
            }
        },
    },
});
